const messages = {
    promotions: 'Promotions',
    addPromotion: 'Add Promotion',
    addNewSaleProduct: 'Add New Sale Product',
    editSaleProduct: 'Edit Sale Product',
    addSaleProductPromotion: 'Add Sale Product Promotion',
    editSaleProductPromotion: 'Edit Sale Product Promotion',
    confirmDisableProduct: 'Confirm Disable Product',
    confirmDeletePromotion: 'Confirm Delete Promotion',
    attributeType: 'Attribute Type',
    date: 'Date',
    Type: 'Type',
    order: 'Order',
    sort: 'Sort',
    ascending: 'Ascending',
    descending: 'Descending',
    assignedDate: 'Assigned Date',
    lastPaymentDate: 'Last Payment Date',
    nextAppointmentDate: 'Next Appointment Date',
    editAppointment: 'Edit Appointment',
    createNewAppointment: 'Create New Appointment',
    patientsSurgicalProvider: "Patient's Surgical Provider",
    allNonSurgical: 'All Non-Surgical',
    patientsAppointments: "Patient's Appointments",
    allAppointments: 'All Appointments',
    assignedDays: 'Assigned Days',
    notInterested: 'Not Interested',
    transfer: 'Transfer',
    myLeads: 'My Leads',
    reassignLead: 'Reassign Lead',
    selectASeller: 'Select a Seller',
    uploadChartDocument: 'Upload Chart Document',
    requestPayment: 'Request Payment',
    requestPaymentNoQuoteMessage: 'A quote must be created before payments can be requested.',
    paymentLinks: 'Payment Links',
    paymentLink: 'Payment Link',
    createdAt: 'Created At',
    linkCopiedToClipboard: 'Link copied to clipboard.',
    sendPaymentLink: 'Send Payment Link',
    link: 'Link',
    text: 'Text',
    providerRequiredForSurgicalProcedures: 'Provider is required for surgical procedures.',
    selectAProcedure: 'Select a Procedure',
    selectAProvider: 'Select a Provider',
    quote: 'Quote',
    upgrade: 'Upgrade',
    priceOverride: 'Override Procedure Prices',
    priceTreatmentOverride: 'Override Treatment Prices',
    assignmentHistory: 'Assignment History',
    recuperate: 'Recuperate',
    recuperation: 'Recuperation',
    recuperable: 'Recuperable',
    return: 'Return',
    assignedTimes: 'Number of Times Assigned',
    addToQuote: 'Add to Quote',
    changesSaved: 'Changes saved.',
    addItem: 'Add Item',
    addAccessory: 'Add Accessory',
    discounts: 'Discounts',
    patientsByDebtRange: 'Patients by Debt Range',
    debtsPerDay: 'Debts Per Day',
    salesPerDay: 'Sales Per Day',
    birthday: 'Birthday',
    transactionCost: 'Transaction Cost',
    total: 'Total',
    paymentDetails: 'Payment Details',
    last4CardNumber: 'Card Number',
    addAssistant: 'Add Assistant',
    assignAssistants: 'Assign Assistants',
    assignAssistant: 'Assign Assistant',
    assistant: 'Assistant',
    pendingBalance: 'Pending Balance',
    quoteTotal: 'Quote Total',
    reassign: 'Reassign',
    effectiveness: 'Effectiveness',
    assignedLeads: 'Assigned Leads',
    leadsAttended: 'Leads Attended',
    newClients: 'New Leads',
    clientsWithPhotos: 'New Photo',
    walkInClients: 'Walk In Patients',
    surgerySold: 'Surgery Sold',
    cosmetologySold: 'Cosmetology Sold',
    balanceSold: 'Balance Sold',
    totalCharged: 'Total Charged',
    minimumGreaterThanMaximum:
        "The value of the 'Sales minimum' filter cannot be greater than the value of the 'Maximum sales' filter.",
    inactiveSellers: 'Inactive Sellers',
    firstAppSinceAssignmentDate: 'First appointment since assignment date',
    criterea: 'Criteria',
    includeCriterea: 'Include Criteria',
    excludeCriterea: 'Exclude Criteria',
    assignedToSeller: 'Assigned to seller',
    hasQuote: 'Has Quote',
    refund: 'Refund',
    updateRefund: 'Update refund',
    clientIdNotEqual: 'Client Id are not equals',
    refundDepositTo: 'Refund deposit to',
    cancelDepositTo: 'Cancel deposit to',
    paymentReference: 'Payment reference',
    surgerySales: 'Surgery sales by office',
    surgerySalesDescription:
        'Shows the assigned leads, new patients sold and balance grouped by office',
    cosmetologySalesByOffice: 'Cosmetology sales by office',
    cosmetolySalesByOfficeDescription:
        'Get information amount non surgical procedures sold on each department',
    earningByOfficeReport: 'Earnings by office',
    earningByOfficeReportDescription:
        'Shows total earnings by department. Including deposits, product sales and non surgical procedures',
    totalSalesByOffice: 'Total sales by office',
    totalSalesByOfficeDescription: 'All sales from the company aggregated on a single place',
    doneSurgeriesByDr: 'Completed surgeries by doctor',
    doneSurgeriesByDrDescription:
        'See how many surgeries a doctor has completed in a period of time',
    newLeadsByDateRange: 'New Leads ',
    newLeadsByDateRangeDescription: 'See how many NEW LEADS were created in a period of time',
    leaveBlankToShowCurrentWeek: 'Leave blank to see current week',
    marketingUser: 'Marketing User',
    pendingIncomingTransfer: 'Pending incoming transfer',
    maxAmountToTransferTo: 'Max amount to transfer to',
    transferAmount: 'Transfer amount',
    destination: 'Destination',
    transferMovements: 'Transfer Movements',
    sourceQuote: 'Source quote',
    activeQuotes: 'Active quotes',
    sourceItems: 'Source items',
    balanceTransferRequest: 'Balance to quote transfer request',
    quoteToBalanceTransferRequest: 'Quote to balance transfer request',
    transferToTitle: 'Transfer to {item} from Quote #{quote}',
    cancelQuoteTransferRequestMessage:
        'Please write down why you want to cancel this transfer request.',
    cancelledBy: 'Cancelled by',
    allQuoteTransfers: 'All Quote Transfers',
    authorizeTransfer: 'Authorize Transfer #{transfer}',
    doneMassageByMasseuse: 'Completed massage by masseuse',
    doneMassageByMasseuseDescription:
        'See how many massages a masseuse has completed in a period of time',
    quoteId: 'Quote ID',
    quoteAuditHistory: 'Quote Audit History',
    source: 'Source',
    remaining: 'Remaining',
    maxAmount: 'Max amount',
    financedPrice: 'Financed (This is the amount the client is financing)',
    financed: 'Financed',
    addFinancedPayment: 'Add financed payment',
    accredit: 'Accredit',
    discredit: 'Discredit',
    changeDate: 'Change date',
    pendingInvoice: 'Pending invoice',
    linkFee: 'Link fee',
    authorizeAccessoryRefund: 'Authorize accessory refund',
    transferFromBalance: 'Transfer from Balance',
    transferToBalance: 'Transfer to Balance',
    emptyDepositItems: 'You have not added any items',
    emptyDepositItemsHelpText: 'Start adding new items to the deposit',
    addNewDepositItem: 'Add new deposit item',
    addNewDepositItemHelpText: 'Add new item to the deposit',
    transfers: 'Transfers',
    co: 'CO',
    coSold: 'CO Sold',
    va: 'VA',
    vaSold: 'VA Sold',
    laser: 'Laser',
    laserSold: 'Laser Sold',
    ra: 'RA',
    cav: 'CAV',
    money: 'Money',
    netMoney: 'Net Money',
    walkInCiClients: 'Walk in CI',
    walkInSoldClients: 'Sold',
    clientsWithSoldPhotos: 'Sold Photos',
    clientsWithPhotosAssigned: 'Assigned Photos',
    leadAssignedPublicity: 'Publicity Assigned Leads',
    leadAssignedOthers: ' Other Assigned Leads',
    leadSoldPublicity: 'Publicity Sold Leads',
    leadSoldOthers: 'Other Sold Leads',
    stay: 'Stay',
    totalSales: 'Total Sales',
    authorizeProcedureRefund: 'Authorize procedure refund',
    cosmetologyProcedureByDepartment: 'Cosmetology procedures sales',
    cosmetologyProcedureByDepartmentDescription:
        'See how much much money was earn in cosmetology procedures',
    invalidInvoice: 'Invalid invoice, please check the total invoice and total paid',
    currentInvoice: 'Current invoice',
    quoteInvoices: 'Quote invoices',
    invalid: 'Invalid',
    LeadsByInactiveSellers: 'Leads by inactive Sellers',
};

export default messages;
